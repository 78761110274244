import * as React from "react";

import Layout from "../components/Layout/Layout";
import NewPassword from "../components/newPassword/NewPassword";

const newPassword = () => {
  return (
    <Layout>
      <NewPassword />
    </Layout>
  );
};

export default newPassword;
