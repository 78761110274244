import * as React from "react";
// @ts-ignore
import Logo from "../../../images/logo.png";
// @ts-ignore
import SearchIcon from "../../../images/search_icon.svg";
// @ts-ignore
import MenuIcon from "../../../images/menu.svg";

import { useState } from "react";
import { Link } from "gatsby";
import { injectIntl } from "gatsby-plugin-intl";

import parse from "html-react-parser";
import Eye from "../../images/eye.svg";
import "./NewPassword.css";

// @ts-ignore
const NewPassword = ({ intl }) => {
  return (
    <header className="flex justify-center items-center pb-[10rem] bg-[#FAFAFA] ">
      <div className="flex flex-col   xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
        <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] "></div>
        <div className="flex flex-col ">
          {/*title*/}
          <h3 className="sm:w-auto xs:w-[90%] font-medium text-[1.5rem]">
            {parse(
              intl.formatMessage({ id: "ResetPassword.NewPassword.Title" })
            )}
          </h3>
          {/*inputs*/}
          <div className="flex sm:flex-row xs:flex-col sm:mt-[2rem] xs:mt-[1rem] sm:gap-9 xs:gap-4">
            <div className="grid sm:grid-flow-row ">
              <p className="font-medium text-[1rem]">
                {parse(
                  intl.formatMessage({
                    id: "ResetPassword.NewPassword.EnterPassword",
                  })
                )}
              </p>
              <input
                type="text"
                placeholder="Пароль"
                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
              />
              <img src={Eye} alt="" className="eye cursor-pointer" />
            </div>
            <div className="grid sm:grid-flow-row ">
              <p className="font-medium text-[1rem]">
                {parse(
                  intl.formatMessage({
                    id: "ResetPassword.NewPassword.RepeatPassword",
                  })
                )}
              </p>
              <input
                type="text"
                placeholder="Пароль"
                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
              />
              <img src={Eye} alt="" className="eye cursor-pointer" />
            </div>
          </div>
          {/*button*/}
          <button className="mt-[1rem] w-[9rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#FF922E]">
            {parse(
              intl.formatMessage({ id: "ResetPassword.NewPassword.Button" })
            )}
          </button>
        </div>
      </div>
    </header>
  );
};

export default injectIntl(NewPassword);
